import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { useSnackbar } from "notistack";

import ChangePassword from "../components/ChangePassword";
import GenericContainer from "../../../components/containers/GenericContainer";

import useAuth from "../../../hooks/useAuth";
import { updateVendor } from "../api";

const useStyles = makeStyles((theme) => ({
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  button: {
    boxShadow: "none",
  },
  mobileField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  infoIcon: {
    marginLeft: "8px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
  primaryButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  },
}));

const AccountSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user: vendor, setUser: setVendor, refresh } = useAuth();

  const handleInputChange = (e) => {
    setVendor({
      ...vendor,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateDetails = () => {
    updateVendor(vendor)
      .then((res) => {
        // console.log(res);
        enqueueSnackbar("Details updated successfully!", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Failed to update details!", { variant: "error" });
      })
      .finally(() => refresh());
  };

  return (
    <GenericContainer>
      <div className={classes.detailsSection}>
        <Typography variant="h5" className={classes.header}>
          Account
        </Typography>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          name="vendor_name"
          value={vendor.vendor_name ? vendor.vendor_name : ""}
          onChange={handleInputChange}
        />
        <TextField
          id="email"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="email"
          name="email"
          label="Email"
          value={vendor.email ? vendor.email : ""}
          onChange={handleInputChange}
        />
        <TextField
          id="mobile"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="number"
          className={classes.mobileField}
          name="contact_number"
          label="Mobile"
          value={vendor.contact_number ? vendor.contact_number : ""}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={vendor.consent_to_marketing ? vendor.consent_to_marketing : false}
              onChange={(e) => setVendor({ ...vendor, consent_to_marketing: e.target.checked })}
            />
          }
          label="I'd like to receive occasional marketing content via email."
        />
        <div className={classes.buttonContainer}>
          <Button className={classes.primaryButton} variant="contained" color="primary" onClick={handleUpdateDetails}>
            Save Details
          </Button>
        </div>
      </div>
      <ChangePassword />
    </GenericContainer>
  );
};

export default AccountSettings;
