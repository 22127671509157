import React, { useState, createContext, useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import _ from "lodash";

import { login as loginApi, logout as logoutApi, getJWT, spare, refetchVendor } from "../Auth";
import { BACKEND_URL } from "../config";

const initialContext = {
  user: {},
  loading: false,
  error: false,
  login: () => {},
  logout: () => {},
  refresh: () => {},
};

export const AuthContext = createContext(initialContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const flattenVendor = (vendor) => {
    const { user, ..._vendor } = vendor;
    return { ..._vendor, ...user, order_in_advance: _vendor.days_in_advance > 0 };
  };

  useEffect(() => {
    const id = getJWT();
    if (id && _.isEmpty(user)) {
      const decoded = jwtDecode(id) || {};
      spare.get(`${BACKEND_URL}/v1/users/${decoded.user_id}`).then((res) => setUser(flattenVendor(res.data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (user) => {
    setLoading(true);
    await loginApi(user)
      .then((res) => setUser(flattenVendor(res.data.user.vendor)))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const logout = () => {
    logoutApi();
  };

  const refresh = async () => {
    setLoading(true);
    await refetchVendor()
      .then((res) => setUser(flattenVendor(res.data)))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const memoizedContext = useMemo(
    () => ({
      user: user,
      setUser: setUser,
      loading: loading,
      error: error,
      login: login,
      logout: logout,
      refresh: refresh,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error]
  );

  return <AuthContext.Provider value={memoizedContext}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
