import axios from "axios";
import Cookies from "js-cookie";

import { BACKEND_URL, API_VERSION } from "./config";

export const client = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

export const spare = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

// const auth = axios.create({
//   withCredentials: true,
// });

// set request interceptor to use access token if exists
client.interceptors.request.use(
  (config) => {
    // console.log("req intercepted");
    if (Cookies.get("t1")) {
      // console.log(Cookies.get("t1"));
      config.headers.Authorization = `Bearer ${Cookies.get("t1")}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// intercept 401 response, silent refresh
client.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originReq = err.config;
      // console.log(originReq);
      if (err.response && err.response.status === 401 && originReq && !originReq.isRetryRequest) {
        originReq.isRetryRequest = true;
        // console.log("refresh token sent");
        const q = spare
          .post(`/refresh`)
          .then((res) => {
            // set new JWT
            client.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
            originReq.headers.Authorization = `Bearer ${res.data.access}`;
            Cookies.set("t1", res.data.access);
            return client(originReq);
          })
          .catch((err) => {
            Cookies.remove("t1");
            window.location.href = "/";
          });

        resolve(q);
      }

      return reject(err);
    });
  }
);

export const getJWT = () => {
  return Cookies.get("t1");
};

export const register = (newUser, referralCode = "") => {
  return client.post(`/${API_VERSION}/vendors`, newUser, { params: { ref: referralCode } });
};

export const login = (user) => {
  // console.log("login");
  return spare.post(`/auth`, { ...user, tz: Intl.DateTimeFormat().resolvedOptions().timeZone }).then((res) => {
    // console.log(res);
    client.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
    Cookies.set("t1", res.data.access);
    return res;
  });
};

export const logout = () => {
  // remove refresh token cookie
  return client.post(`${BACKEND_URL}/logout`).then((res) => {
    // console.log(res);
    Cookies.remove("t1");
    return res;
  });
};

export const refetchVendor = () => {
  return client.get(`${BACKEND_URL}/${API_VERSION}/vendors`);
};

export const sendResetPasswordLink = (email) => {
  return spare.post(`${BACKEND_URL}/reset-password-link`, { email });
};

export const resetPassword = (jwt, password) => {
  return spare.post(`${BACKEND_URL}/reset-password`, { password }, { headers: { Authorization: `Bearer ${jwt}` } });
};

const Auth = {
  client,
  login,
  logout,
  register,
  getJWT,
};

export default Auth;
